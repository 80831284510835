.story{
  margin-top: 5vh;
  width: 100%;
  max-width: 1750px;
  transition: all ease-in-out 0.1s;
  // background-image: url('./img/bg.png');
  min-height: 590px;
  background-repeat: no-repeat;
  background-position: top center;
  @media screen and (max-width: 1750px) {
    border-radius: 70px;
  }
  @media screen and (max-width: 1366px) {
    border-radius: 0;
  }
  .scene{
    position: relative;
    min-height: 590px;

    .front{
      z-index: 0;
      position: absolute;
      bottom: 0px;
      left: 0vw;
      right: 0;
      margin: auto;
      width: 100%;
      height: 535px;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url('./img/front.png');
    }
    .front2{
      z-index: -1;
      position: absolute;
      bottom: 40px;
      left: 0vw;
      right: 0;
      margin: auto;
      width: 100%;
      height: 230px;
      background-position: 50px center;
      background-repeat: no-repeat;
      background-image: url('./img/front2.png');
      @media screen and (max-width: 500px) {
        background-position: center;
      }
    }
    .back{
      z-index: -2;
      position: absolute;
      bottom: 5vh;
      left: 0vh;
      right: 0;
      margin: auto;
      width: 100%;
      height: 535px;
      background-image: url('./img/back.png');
      background-repeat: no-repeat;
      background-position: center center;
    }

    .be-part{
      position: absolute;
      left: 0;
      right: 0;
      top: 18vh;
      z-index: -1;
      margin: auto;
      @media screen and (max-width: 1366px) {
        width: 90%;
        top: 25vh;
      }
    }

    img.chicken1 {
      position: absolute;
      top: -3vh;
      right: 10vw;
      z-index: 5;
    }

    img.chicken2 {
      position: absolute;
      bottom: -2vh;
      left: 10vw;
      z-index: 5;
    }
  }

  .story-content{
    width: 100%;
    max-width: 1000px;
    margin: auto;
    font-family: "Mulish", sans-serif;
    font-weight: 500;
    font-style: normal;

    @media screen and (max-width: 1366px) {
      padding: 0;
      box-sizing: border-box;
    }

    h3{
      font-size: 50px;
      font-family: 'BellCentBdListEU';
      color: #fdc348;
      @media screen and (max-width: 1366px) {
        padding: 0 20px;
        box-sizing: border-box;
      }
      @media screen and (max-width: 700px) {
        font-size: 40px;
      }
      @media screen and (max-width: 500px) {
        font-size: 8vw;
      }
    }
    h5{
      font-size: 34px;
      margin: 0;
      @media screen and (max-width: 1366px) {
        padding: 0 20px;
        box-sizing: border-box;
      }
      @media screen and (max-width: 500px) {
        font-size: 6vw;
      }
    }
    p{
      font-size: 22px;
      @media screen and (max-width: 1366px) {
        padding: 0 20px;
        box-sizing: border-box;
      }
      @media screen and (max-width: 500px) {
        font-size: 5vw;
      }
    }

    .story-li{
      background-color: #e1f9f4;
      display: flex;
      flex-direction: column;
      padding: 50px;
      border-radius: 70px;
      margin-top: 10vh;

      @media screen and (max-width: 1366px) {
        border-radius: 0px;
      }
      @media screen and (max-width: 700px) {
        gap: 30px;
      }

      .story-item{
        display: flex;
        align-items: center;
        text-align: left;
        gap: 40px;

        @media screen and (max-width: 700px) {
          flex-direction: column;
          text-align: center;
          gap: 5px;
          
        }

        .point{
          color: #5ec1da;
          font-size: 70px;
          font-weight: bolder;
        }
        .txt{
          font-size: 19px;
          @media screen and (max-width: 500px) {
            font-size: 5vw;
          }
        }        
      }


    }
  }

}

