.header {
  background-color: #9acdfe;
  height: 650px;
  border-radius: 70px;
  padding: 30px 40px;
}
@media screen and (max-width: 1700px) {
  .header {
    height: 80vh;
  }
}
@media screen and (max-width: 1366px) {
  .header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 0px;
    padding: 0px;
    width: 100%;
  }
}
.header .navbar {
  display: flex;
  width: 100%;
  gap: 75px;
  align-items: center;
}
@media screen and (max-width: 700px) {
  .header .navbar .logo {
    margin: 15px 0;
  }
}
.header .navbar .logo img {
  max-width: 240px;
}
@media screen and (max-width: 700px) {
  .header .navbar .logo img {
    width: 200px;
  }
}
.header .navbar .nav {
  background-color: #111827;
  width: 100%;
  border-radius: 100px;
  height: 58px;
}
@media screen and (max-width: 1366px) {
  .header .navbar .nav {
    display: none;
  }
}
.header .hero-header {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.header .hero-header .hero-header-1 {
  width: 40%;
  margin: -1% 0 -3% 0;
  max-width: 880px;
}
@media screen and (max-width: 1366px) {
  .header .hero-header .hero-header-1 {
    width: 55%;
    margin: -5% 0 -3% 0;
  }
}
.header .hero-header .hero-header-2 {
  width: 90%;
  max-width: 1440px;
}
@media screen and (max-width: 1366px) {
  .header .hero-header .hero-header-2 {
    width: 90%;
    margin: -1% 0 -3% 0;
  }
}
.header .hero-hero {
  position: relative;
  max-width: 1550px;
  width: 95%;
  display: grid;
  grid-template-columns: 30% 30% 40%;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". . .";
  align-items: end;
  margin: auto;
  top: -21vw;
  padding: 0 5vw;
  box-sizing: border-box;
}
@media screen and (max-width: 1440px) {
  .header .hero-hero {
    padding: 0;
  }
}
@media screen and (max-width: 1366px) {
  .header .hero-hero {
    top: auto;
    bottom: 90px;
    left: 0;
    grid-template-columns: 100%;
    grid-template-areas: ".";
    width: 100%;
    min-height: 672px;
  }
}
.header .hero-hero .hero-img-1 {
  width: 130%;
  height: auto;
  max-width: 625px;
}
@media screen and (max-width: 1440px) {
  .header .hero-hero .hero-img-1 {
    width: 160%;
  }
}
@media screen and (max-width: 1366px) {
  .header .hero-hero .hero-img-1 {
    display: none;
  }
}
.header .hero-hero .hero-img-2 {
  position: relative;
  bottom: -1vh;
  max-width: 677px;
  height: auto;
}
@media screen and (max-width: 1366px) {
  .header .hero-hero .hero-img-2 {
    max-height: 551px;
    left: 0;
    right: 0;
    margin: auto;
    position: relative;
    height: 100vh;
  }
}
.header .hero-hero .hero-img-3 {
  max-height: 610px;
}
@media screen and (max-width: 1440px) {
  .header .hero-hero .hero-img-3 {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 1366px) {
  .header .hero-hero .hero-img-3 {
    display: none;
  }
}
.header .hero-hero img {
  height: 85%;
}/*# sourceMappingURL=styles.css.map */