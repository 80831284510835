.navbar{
  display: flex;
  width: 100%;
  gap: 75px;
  align-items: center;

  @media screen and (max-width: 1000px) {
    gap: 0;
    flex-direction: column;
  }

  .logo{
    img{
      max-width: 240px;
    }
  }
  .nav{
    background-color: #111827;
    width: 100%;
    border-radius: 100px;
    height: 58px;
    display: flex;
    justify-content: space-between;
    padding: 0 25px 0 0;
    align-items: center;
    z-index: 9;

    ul.page-navigation{
      display: flex;
      gap: 2.2vw;
      list-style: none;
      a{
        color: #fff;
        text-decoration: none;
        transition: color ease-in-out .1s;
        &:hover{
          color: #ff0000;
        }
      }
    }  
    ul.social{
      display: flex;
      gap: 1vw;
      list-style: none;
      align-items: center;
      line-height: 1;
      
      button{
        border: 0;
        background-color: transparent;
        margin: 0;
        padding: 0;
        cursor: pointer;
        
        img{
          max-width: 20px;
        }        
      }

    }  
  }
}