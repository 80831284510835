.footer {
  width: 100%;
  margin: 5vh auto 5vh auto;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 1366px) {
  .footer {
    padding: 0 20px;
    box-sizing: border-box;
  }
}
.footer h3 {
  font-size: 50px;
  font-family: "BellCentBdListEU";
  margin-bottom: 0;
}
@media screen and (max-width: 700px) {
  .footer h3 {
    font-size: 40px;
  }
}
@media screen and (max-width: 500px) {
  .footer h3 {
    font-size: 8vw;
    padding: 0 20px;
  }
}
.footer h4 {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 30px;
  margin: 0 0 50px 0;
}
@media screen and (max-width: 700px) {
  .footer h4 {
    font-size: 30px;
  }
}
@media screen and (max-width: 500px) {
  .footer h4 {
    font-size: 6vw;
    padding: 0 20px;
  }
}
.footer h4 a {
  color: #ab7345;
  text-decoration: none;
}
.footer .footer-chicken {
  position: absolute;
  right: 0;
  bottom: 0vh;
}
@media screen and (max-width: 1600px) {
  .footer .footer-chicken {
    border-radius: 70px;
    width: 400px;
    right: -100px;
  }
}
@media screen and (max-width: 900px) {
  .footer .footer-chicken {
    border-radius: 70px;
    width: 300px;
    right: -100px;
  }
}
@media screen and (max-width: 700px) {
  .footer .footer-chicken {
    display: none;
  }
}
.footer button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  transition: all ease-in-out 0.15s;
}
.footer button:hover {
  opacity: 0.7;
}/*# sourceMappingURL=styles.css.map */