.token {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-end;
  z-index: 9;
  width: 100%;
  justify-content: space-evenly;
  max-width: 1300px;
  margin: 10vh auto;
}
@media screen and (max-width: 800px) {
  .token {
    flex-direction: column;
    gap: 30px;
    align-items: center;
    margin-top: 10vh;
  }
}/*# sourceMappingURL=styles.css.map */