.hero{
  padding: 15px 0;
  @media screen and (max-width: 700px) {
    padding: 15px !important;
  }
  .red_font{
    color: #ff0000;
  }
}

