.hero {
  padding: 15px 0;
}
@media screen and (max-width: 700px) {
  .hero {
    padding: 15px !important;
  }
}
.hero .red_font {
  color: #ff0000;
}/*# sourceMappingURL=styles.css.map */