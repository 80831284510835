.message {
  background: #ffdb00;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  width: auto;
  position: fixed;
  padding: 5px 50px;
  top: 15px !important;
  left: 0;
  right: 0;
  z-index: 999;
  margin: auto;
  max-width: 150px;
}

.chicken {
  position: absolute;
  left: -250px !important;
  margin-top: -100px !important;
  z-index: 1;
}
@media screen and (max-width: 1700px) {
  .chicken {
    display: none;
  }
}

.token-sums {
  background: #008e9a;
  width: 100%;
  max-width: 1750px;
  border-radius: 70px;
  transition: all ease-in-out 0.1s;
  position: relative;
}
@media screen and (max-width: 1366px) {
  .token-sums {
    border-radius: 0px;
  }
}
.token-sums .token-sums-content {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-end;
  z-index: 9;
  justify-content: space-evenly;
  margin: auto;
  max-width: 1300px;
  padding: 55px 0 35px 0;
  color: #fff;
  letter-spacing: 0.5px;
}
.token-sums .token-sums-content img {
  max-width: 200px;
  position: relative;
  z-index: 0;
}
@media screen and (max-width: 700px) {
  .token-sums .token-sums-content img {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
}
.token-sums .token-sums-content h4 {
  color: #f6bf02;
  margin-top: 5px;
}
.token-sums .token-sums-content .icon-1 {
  min-width: 300px;
}
@media screen and (max-width: 700px) {
  .token-sums .token-sums-content .icon-1 {
    width: 100%;
    min-width: unset;
  }
}
.token-sums .token-sums-content .icon-1 h3 {
  font-size: 28px;
  margin: 0;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 700px) {
  .token-sums .token-sums-content .icon-1 h3 {
    font-size: 9vw;
    width: 100%;
  }
}
.token-sums .token-sums-content .icon-2 {
  min-width: 300px;
}
@media screen and (max-width: 700px) {
  .token-sums .token-sums-content .icon-2 {
    width: 100%;
    min-width: unset;
  }
}
.token-sums .token-sums-content .icon-2 h3 {
  font-size: 28px;
  margin: 0;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 700px) {
  .token-sums .token-sums-content .icon-2 h3 {
    font-size: 9vw;
    width: 100%;
  }
}
.token-sums .token-sums-content .icon-3 {
  min-width: 300px;
}
@media screen and (max-width: 700px) {
  .token-sums .token-sums-content .icon-3 {
    width: 100%;
    min-width: unset;
  }
}
.token-sums .token-sums-content .icon-3 h3 {
  font-size: 12px;
  position: relative;
  z-index: 2;
  display: flex;
}
@media screen and (max-width: 700px) {
  .token-sums .token-sums-content .icon-3 h3 {
    text-align: center;
    align-items: center;
    gap: 13px;
    font-size: 3vw;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
}
.token-sums .token-sums-content .icon-3 h3 button.copy {
  border: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: #000000;
  font-size: 22px;
  position: absolute;
  top: -10px;
  right: -46px;
  background: #ffdb00;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0.15s;
}
@media screen and (max-width: 700px) {
  .token-sums .token-sums-content .icon-3 h3 button.copy {
    position: relative;
    right: 0;
    top: 0;
  }
}
.token-sums .token-sums-content .icon-3 h3 button.copy:hover {
  background: #feeb6a;
}
@media screen and (max-width: 1200px) {
  .token-sums .token-sums-content {
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }
}/*# sourceMappingURL=styles.css.map */