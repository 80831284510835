.story {
  margin-top: 5vh;
  width: 100%;
  max-width: 1750px;
  transition: all ease-in-out 0.1s;
  min-height: 590px;
  background-repeat: no-repeat;
  background-position: top center;
}
@media screen and (max-width: 1750px) {
  .story {
    border-radius: 70px;
  }
}
@media screen and (max-width: 1366px) {
  .story {
    border-radius: 0;
  }
}
.story .scene {
  position: relative;
  min-height: 590px;
}
.story .scene .front {
  z-index: 0;
  position: absolute;
  bottom: 0px;
  left: 0vw;
  right: 0;
  margin: auto;
  width: 100%;
  height: 535px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("./img/front.png");
}
.story .scene .front2 {
  z-index: -1;
  position: absolute;
  bottom: 40px;
  left: 0vw;
  right: 0;
  margin: auto;
  width: 100%;
  height: 230px;
  background-position: 50px center;
  background-repeat: no-repeat;
  background-image: url("./img/front2.png");
}
@media screen and (max-width: 500px) {
  .story .scene .front2 {
    background-position: center;
  }
}
.story .scene .back {
  z-index: -2;
  position: absolute;
  bottom: 5vh;
  left: 0vh;
  right: 0;
  margin: auto;
  width: 100%;
  height: 535px;
  background-image: url("./img/back.png");
  background-repeat: no-repeat;
  background-position: center center;
}
.story .scene .be-part {
  position: absolute;
  left: 0;
  right: 0;
  top: 18vh;
  z-index: -1;
  margin: auto;
}
@media screen and (max-width: 1366px) {
  .story .scene .be-part {
    width: 90%;
    top: 25vh;
  }
}
.story .scene img.chicken1 {
  position: absolute;
  top: -3vh;
  right: 10vw;
  z-index: 5;
}
.story .scene img.chicken2 {
  position: absolute;
  bottom: -2vh;
  left: 10vw;
  z-index: 5;
}
.story .story-content {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-style: normal;
}
@media screen and (max-width: 1366px) {
  .story .story-content {
    padding: 0;
    box-sizing: border-box;
  }
}
.story .story-content h3 {
  font-size: 50px;
  font-family: "BellCentBdListEU";
  color: #fdc348;
}
@media screen and (max-width: 1366px) {
  .story .story-content h3 {
    padding: 0 20px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 700px) {
  .story .story-content h3 {
    font-size: 40px;
  }
}
@media screen and (max-width: 500px) {
  .story .story-content h3 {
    font-size: 8vw;
  }
}
.story .story-content h5 {
  font-size: 34px;
  margin: 0;
}
@media screen and (max-width: 1366px) {
  .story .story-content h5 {
    padding: 0 20px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 500px) {
  .story .story-content h5 {
    font-size: 6vw;
  }
}
.story .story-content p {
  font-size: 22px;
}
@media screen and (max-width: 1366px) {
  .story .story-content p {
    padding: 0 20px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 500px) {
  .story .story-content p {
    font-size: 5vw;
  }
}
.story .story-content .story-li {
  background-color: #e1f9f4;
  display: flex;
  flex-direction: column;
  padding: 50px;
  border-radius: 70px;
  margin-top: 10vh;
}
@media screen and (max-width: 1366px) {
  .story .story-content .story-li {
    border-radius: 0px;
  }
}
@media screen and (max-width: 700px) {
  .story .story-content .story-li {
    gap: 30px;
  }
}
.story .story-content .story-li .story-item {
  display: flex;
  align-items: center;
  text-align: left;
  gap: 40px;
}
@media screen and (max-width: 700px) {
  .story .story-content .story-li .story-item {
    flex-direction: column;
    text-align: center;
    gap: 5px;
  }
}
.story .story-content .story-li .story-item .point {
  color: #5ec1da;
  font-size: 70px;
  font-weight: bolder;
}
.story .story-content .story-li .story-item .txt {
  font-size: 19px;
}
@media screen and (max-width: 500px) {
  .story .story-content .story-li .story-item .txt {
    font-size: 5vw;
  }
}/*# sourceMappingURL=styles.css.map */