.header{
  background-color: #9acdfe;
  height: 650px;
  border-radius: 70px;
  padding: 30px 40px;

  @media screen and (max-width: 1700px) {
    height: 80vh;
  }

  @media screen and (max-width: 1366px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 0px;
    padding: 0px;
    width: 100%;
  }


  .navbar{
    display: flex;
    width: 100%;
    gap: 75px;
    align-items: center;


    .logo{
      @media screen and (max-width: 700px) {
        margin: 15px 0;
      }
      img{
        max-width: 240px;
        @media screen and (max-width: 700px) {
          width: 200px;
        }
      }
    }
    .nav{
      background-color: #111827;
      width: 100%;
      border-radius: 100px;
      height: 58px;
      
      @media screen and (max-width: 1366px) {
        display: none;
      }
    }
  }
  .hero-header {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .hero-header-1{
      width: 40%;
      margin: -1% 0 -3% 0;
      max-width: 880px;
      @media screen and (max-width: 1366px) {
        width: 55%;
        margin: -5% 0 -3% 0;
      } 


    }
    .hero-header-2{
      width: 90%;
      max-width: 1440px;
      @media screen and (max-width: 1366px) {
        width: 90%;
        margin: -1% 0 -3% 0;
      } 
    }

  }
  .hero-hero {
    position: relative;
    max-width: 1550px;
    width: 95%;
    display: grid;
    grid-template-columns: 30% 30% 40%;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: ". . .";
    align-items: end;
    margin: auto;
    top: -21vw;
    padding: 0 5vw;
    box-sizing: border-box;

    @media screen and (max-width: 1440px) {
      padding: 0;
    }

    @media screen and (max-width: 1366px) {
      top: auto;
      bottom: 90px;
      left: 0;
      grid-template-columns: 100%;
      grid-template-areas: ".";
      width: 100%;
      min-height: 672px;
    }

    .hero-img-1{
      width: 130%;
      height: auto;
      max-width: 625px;
      @media screen and (max-width: 1440px) {
        width: 160%;
      } 
      @media screen and (max-width: 1366px) {
        display: none;
      } 
    }
    .hero-img-2{
      position: relative;
      bottom: -1vh;
      max-width: 677px;
      height: auto;
      @media screen and (max-width: 1366px) {
        max-height: 551px;
        left: 0;
        right: 0;
        margin: auto;
        position: relative;
        height: 100vh;
      }  
    }
    .hero-img-3{
      max-height: 610px;
      @media screen and (max-width: 1440px) {
        width: 100%;
        height: auto;
      } 
      @media screen and (max-width: 1366px) {
        display: none;
      }  
    }

    img{
      height: 85%;
    }
  }

}

