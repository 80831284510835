.navbar {
  display: flex;
  width: 100%;
  gap: 75px;
  align-items: center;
}
@media screen and (max-width: 1000px) {
  .navbar {
    gap: 0;
    flex-direction: column;
  }
}
.navbar .logo img {
  max-width: 240px;
}
.navbar .nav {
  background-color: #111827;
  width: 100%;
  border-radius: 100px;
  height: 58px;
  display: flex;
  justify-content: space-between;
  padding: 0 25px 0 0;
  align-items: center;
  z-index: 9;
}
.navbar .nav ul.page-navigation {
  display: flex;
  gap: 2.2vw;
  list-style: none;
}
.navbar .nav ul.page-navigation a {
  color: #fff;
  text-decoration: none;
  transition: color ease-in-out 0.1s;
}
.navbar .nav ul.page-navigation a:hover {
  color: #ff0000;
}
.navbar .nav ul.social {
  display: flex;
  gap: 1vw;
  list-style: none;
  align-items: center;
  line-height: 1;
}
.navbar .nav ul.social button {
  border: 0;
  background-color: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.navbar .nav ul.social button img {
  max-width: 20px;
}/*# sourceMappingURL=styles.css.map */