.guide{
  width: 100%;
  max-width: 1500px;
  margin: 5vh auto 0 auto;
  border-radius: 70px;
  transition: all ease-in-out 0.1s;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
  padding: 80px;
  box-sizing: border-box;
  min-height: 600px;

  @media screen and (max-width: 1366px) {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  @media screen and (max-width: 700px) {
    padding: 0;
  }


  .guide-chickens{
    .guide-chickens-img{
      width: 100%;
      @media screen and (max-width: 1366px) {
        width: 80%;
        margin: 50px auto 50px auto !important;
      }
    }    
  }


  .info{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h2{
      font-size: 50px;
      margin: 0;
      @media screen and (max-width: 700px) {
        font-size: 40px;
      }
      @media screen and (max-width: 500px) {
        font-size: 8vw;
        padding: 0 20px;
      }
    }
    h4{
      font-size: 20px;
      margin: 2vh 0 2vh 0;
      font-family: "Mulish", sans-serif;
      font-weight: 500;
      font-style: normal;
      max-width: 70%;
      @media screen and (max-width: 1366px) {
        font-size: 22px;
      }
      @media screen and (max-width: 500px) {
        font-size: 5vw;
        padding: 0 20px;
      }
    }

    .options{
      margin-top: 50px;
      display: flex;
      gap: 20px;

      @media screen and (max-width: 500px) {
        flex-direction: column;
        align-items: center;
      }

      .option{
        button{
          background-color: #ffdb00;
          display: flex;
          gap: 10px;
          border: 0;
          align-items: center;
          justify-content: center;
          padding: 10px 28px;
          border-radius: 15px;
          transition: all ease .15s;
          font-size: 15px;
          font-family: 'BellCentBdListEU';
          cursor: pointer;
          line-height: 1;
          color: #000;

          @media screen and (max-width: 500px) {
            width: 100%;
          }

          &:hover{
            background: #feeb6a;
          }
        }

      }
    }
    

  }
}

