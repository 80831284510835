.buy-here{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5vh 0;
  gap: 20px;


  button{
    background-color: #ffdb00;
    border: 0;
    border-radius: 20px;
    font-size: 24px;
    font-family: "BellCentBdListEU";
    padding: 20px 50px 23px 50px;
    cursor: pointer;
    transition: all ease-in-out .15s;
    color: #000;
    
    @media screen and (max-width: 700px) {
      width: 80%;
      font-size: 6vw;
      padding: 20px 10px;
    }

    &:hover{
      background-color: #feeb6a;
    }
  }
}