@font-face {
  font-family: 'BellCentBdListEU';
  src: url('./fonts/BellCentBdListEU.ttf') format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

html {
  scroll-behavior: smooth;
  body{
    overflow-x: hidden;
    .App {
      text-align: center;
      font-family: 'BellCentBdListEU';
      letter-spacing: -1px;
      width: 100%;
      max-width: 1750px;
      margin: auto;
  
      @media screen and (max-width: 1750px) {
        padding: 0 50px;
        box-sizing: border-box;
      }
  
      @media screen and (max-width: 1366px) {
        padding: 0;
        box-sizing: border-box;
      }
      
      .hero{
        padding: 15px 0;
      }
    }  
  }
}


