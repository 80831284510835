.justin{
  margin-top: 20vh;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  z-index: 9;

  @media screen and (max-width: 1000px) {
    gap: 15px;
    margin-top: 10vh;
    padding: 0 10px;
  }

  img{
    width: 100%;
    height: auto;
  }

  .justin-txt-1{
    max-width: 969px;
    margin-bottom: -1vw;
    @media screen and (max-width: 1000px) {
      margin-bottom: -6vw;
      width: 90%;
    }
  }
  .justin-txt-2 {
    z-index: 2;
    max-width: 1291px;
  }
  .justin-txt-3{
    margin-top: -1vw;
    max-width: 1101px;
    @media screen and (max-width: 1000px) {
      margin-top: -6vw;
      width: 90%;
    }
  }

}