.available{
  margin-top: 15vh;
  background-color: #ffdb00;
  width: 100%;
  max-width: 1750px;
  border-radius: 70px;
  transition: all ease-in-out 0.1s;
  padding: 80px;
  box-sizing: border-box;
  min-height: 600px;

  @media screen and (max-width: 1366px) {
    height: 100%;
    padding: 40px;
    border-radius: 0;
    padding-left: 6vw;
    padding-right: 6vw;
  }

  .content{
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      ". ."; 
    max-width: 1500px;

    @media screen and (max-width: 1366px) {
      grid-template-columns: 1fr; 
      grid-template-areas: 
        "."; 
    }

    .info{
      display: flex;
      flex-direction: column;
      align-items: center;

      h2{
        font-size: 70px;
        margin: 0;
        @media screen and (max-width: 700px) {
          font-size: 40px;
        }
        @media screen and (max-width: 500px) {
          font-size: 8vw;
          padding: 0 20px;;
        }
      }
      h4{
        font-size: 24px;
        margin: 2vh 0 0 0;
        font-family: "Mulish", sans-serif;
        font-weight: 500;
        font-style: normal;
        max-width: 70%;
        @media screen and (max-width: 700px) {
          font-size: 18px;
        }
      }
      h5{
        font-size: 19px;
        margin: 5vh 0 3vh 0;
      }
      .options{
        display: flex;
        gap: 20px;
        @media screen and (max-width: 700px) {
          flex-direction: column;
          width: 100%;
        }

        .option{
          background-color: #fff;
          border-radius: 20px;
          padding: 30px;
          display: flex;
          flex-direction: column;
          min-width: 166px;
          align-items: center;
          gap: 10px;

          button{
            border: 0;
            background: transparent;
            cursor: pointer;
            transition: all ease-in-out .15s;
            &:hover{
              opacity: .7;
            }
          }
        }
      }
    }
    .chicken-ufo{
      position: relative;

      .chickenufo{
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;
        top: 15vh;
        padding-left: 10px;
        @media screen and (max-width: 1366px) {
          display: none;
        }
      }
      .ufo{
        position: absolute;
        top: -250px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;

        @media screen and (max-width: 1366px) {
          display: none;
        }

      }
    }      
  }
}

